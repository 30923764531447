@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.busLayout {
    background: url('../../assets/images/buslayout.svg') top left no-repeat;
    position: relative;
    width: 1508px;
    height: 398px;
    padding: 20px 20px 20px 110px;

    > div {
        position: relative;
    }

    &.piso0 {
        .volante {
            background: url('../../assets/images/volante.svg') top left no-repeat;
            position: absolute;
            top: 318px;
            left: 60px;
            width: 54px;
            height: 54px;
        }
    }

    .volante {
        background: url('../../assets/images/volante.svg') top left no-repeat;
        position: absolute;
        top: 318px;
        left: 60px;
        width: 54px;
        height: 54px;
    }

    .rows {
        display: flex;
        justify-content: space-around;
        height: 100%;
        gap: 20px;

        .seatsContainer {
            display: flex;
            flex-direction: column-reverse;
            gap: 15px;
            height: 100%;
            width: 100%;
        }
        .seat {
            width: 64px;
            height: 64px;
            background-color: #FFFFFF;
            border: 2px solid #ADC2D7;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            // justify-content: center;
            text-align: center;
            justify-content: space-around;

            &.disabled {
                background-color: #969696;
                cursor: not-allowed;
        
                span {
                    color: #6B6B6B;
                    position: absolute;
                }
                .icon {
                    align-self: flex-end;
                    color: #C8C8C8;
                    padding-left: 30px;
                    width: 28px;
                    height: 28px;
                }
            }

            &.selected {
                background-color: #2CC682;
                border: 1px solid #22B875;
        
                span {
                    color: white;
                }
            }
        }
        .seatGap {
            width: 64px;
            height: 40px;
            // background-color: transparent;
            // border: none;
            // border: 1px solid green;
            // position: absolute;
        }

        .corridor {
            width: 40px;
            height: 100%;
            background-color: transparent;
            margin: 0 10px; 
          }
    }
}
