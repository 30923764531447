@import '../../scss/functions';
@import '../../scss/config';
@import '../../scss/colors';

.top {
  width: 100%;

  .logo {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0;
    width: 100%;
  }

  .middle {
    align-items: flex-start;
    display: flex;
    gap: 30px;
    padding: 30px;
    width: 100%;

    .saleCard {
      background-color: $color__tertiary;
      border-radius: $settings__border_radius;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 20px;
      position: relative;

      &.cardDepartures {
        background-color: white;
        max-width: 868px;
      }

      & > h2 {
        color: #2E2E2E;
        font-weight: 400;
        text-align: center;

        .hImportant {
          font-weight: 600;
        }
      }
    }

    .fareCard {
      background-color: $color__tertiary;
      border-radius: $settings__border_radius;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 20px;
      position: relative;
      height: 100%;
    }

    .searchFormFields {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 30px;
    }
  }
}

.parentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 700px;
  gap: 20px;
  overflow-y: auto;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0%;
    text-align: center;
  }
}

.faresContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  text-align: center;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0%;
    text-align: center;
  }

  .fare {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 44px;
    line-height: 44px;
    letter-spacing: 0%;
  }
}

.container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d3dce6;
  border-radius: 8px;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 104px;
  width: 540px;

  .minusIcon {
    background: url('../../assets/images/minus.svg');
    width: 44px;
    height: 44px;
    border: none;
    cursor: pointer;
  }

  .plusIcon {
    background: url('../../assets/images/plus.svg');
    width: 44px;
    height: 44px;
    border: none;
    cursor: pointer;
  }

  .label {
    color: #14268c;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
  }

  .count {
    color: #e36423;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
  }

  .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
}

.spinnerLoad {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 40.5%;
  position: absolute;
  top: 45%;

  p {
    color: #162687;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 32px;
    margin: 0;
  }

  svg {
    width: 60px;
    height: 60px;
    color: #162687;
  }
}

footer {
  gap: 34px;

  .left {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    max-width: 868px;
  }
}

.hImportant {
  color: #FF5500;
}

.departures {
  display: flex;
  flex-direction: column;
  gap: 9px;
}