@import '../../../scss/functions';
@import '../../../scss/config';
@import '../../../scss/colors';

.passengerView {
  background-color: #FFFFFF;
  padding: 30px;
  position: relative;
  width: 1000px;
  
  &.passengerRound {
    padding: 10px;
  }
  .namePassenger {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-bottom: 30px;

    .seatRound {
     display: flex;
     flex: 1;
     justify-content: center;
    }

    .seat {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      background-color: #2CC682;
      border-radius: $settings__border_radius;
      color: white;
      padding: 32px 30px;
      margin: 0;
      text-align: center;

      span {
        color: white;
        display: block;
        font-size: rem(18px);
        font-weight: bold;
      }
      &.seatPassenger {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        font-size: rem(28px);
        width: 166px;
        height: 60px;
      }
    }

    .data {
      flex: 1;

      h2 {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        font-feature-settings: 'cv11' on;
        color: #2E2E2E;
        margin: 0 0 20px;
      }
      .inputs {
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
    }

    input {
      background: #FAFAFA;
      border: 1px solid #969696;
      border-radius: 12px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-size: 32px;
      font-weight: 400;
      line-height: 32px;
      font-feature-settings: 'cv11' on;
      height: 82px;
      mix-blend-mode: normal;
      padding: 26px;
      width: 100%;
    }

  }

  // .passengersList {
  //   .selected {
  //     background-color: green;
  //   }
  // }

  .optionsSection {
    margin-bottom: 30px;

    h2 {
      display: flex;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-size: 32px;
      font-weight: 600;
      justify-content: center;
      font-feature-settings: 'cv11' on;
      line-height: 32px;
      margin-top: 0;
    }

    .options {
      //align-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;

      .optionWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .option {
        flex: 1;
        //align-items: flex-start;
        height: 108px;
        background-color: #FFFFFF;
        border: 1px solid #ADC2D7;
        border-radius: $settings__border_radius;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 20px;

        .cost {
          h2 {
            color: #2E2E2E;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: rem(32px);
            font-feature-settings: 'cv11' on;
            line-height: 32px;
            margin: 0;
          }
        }

        .description {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: flex-start;

          h2 {
            color: #2E2E2E;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: rem(28px);
            font-feature-settings: 'cv11' on;
            line-height: 32px;
            margin: 0;
          }

          h3 {
            color: #162687;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: rem(32px);
            font-feature-settings: 'cv11' on;
            line-height: 32px;
            margin: 0;
          }

          p {
            color: #131313;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: rem(22px);
            font-feature-settings: 'cv11' on;
            margin: 0;
          }
          &.defaultPassenger {
            p {
              color: red;
              font-weight: 500;
            }
          }
        }

        .icon {
          // border: 2px solid #c528d0ee;
          width: 32px;
          height: 32px;
          color: #2E2E2E;
        }

        .check {
          display: none;
          color: #2CC682;
          width: 32px;
          height: 32px;
        }

        &.selected {
          background-color: #2CC682;

          p {
            color: #FFFFFF;
          }

          h2 {
            color: #FFFFFF;
          }

          .icon {
            color: #FFFFFF;
          }
        }

        &.blockedSeat {
          cursor: not-allowed;
          background-color: $color__disabled;

          h2, p, .icon {
            color: #FFFFFF;
          }
          
        }

        &.checked {
          .check {
            display: block;
          }
        }
      }
    }
    &.messagePassenger {
      // border: 1px solid #831010;
      p {
        color: red;
        font-size: rem(28px);
        margin-top: 20px;
      }
    }
    .alert {
      background-color: $color__tertiary;
      border-radius: $settings__border_radius;
      margin-top: 15px;
      padding: 20px;

      p {
        color: $color__important; 
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(22px);
        font-feature-settings: 'cv11' on;
        margin: 0;
      }
    }
  }

  .bottom {
    //background: $color__tertiary;
    //display: flex;
    // flex-direction: row;
    position: relative;
    //right: 30px;
    //width: 1041px;
    //justify-content: center;
    padding: 30px;
    text-align: center;

    & > * {
      flex: 1;

      &:last-child {
        text-align: center;
      }
    }
  }

}

.keyboardWrapper {
  bottom: 30px;
  display: none;
  position: fixed;
  left: 30px;
  //transform: translateX(-50%);
  z-index: 9999;

  &.keyboardShown {
    display: block;
  }
}